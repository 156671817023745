import React from 'react';
// import styled from 'styled-components';
// import colors from '../../utils/style/colors';


const Socials = () => {
  // const isMobile = useMediaQuery({ maxWidth: 767 });
  // const location = useLocation();

  return (
    <div id="socialsPage">
    {/* Contenu du composant */}
    </div>
  )
};

export default Socials;
