// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/typeface-mina/index.css";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/ade-display/Ade-Display.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Yahren/Yahrenv2ei.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #C6E6D3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'Ade Display';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('opentype');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'Yahren';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
  font-weight: lighter;
  font-style: normal;
}


h1 {
  font-family: 'Ade Display', serif;
  font-weight: lighter;
}


p {
  font-family: "Mina", sans-serif;
}

a{
  font-family: "Mina", sans-serif;
}


button {
  font-family: "Mina", sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,yBAAyB;AAC3B;;AAEA;EACE;aACW;AACb;;;AAGA;EACE,0BAA0B;EAC1B,+DAAkE;EAClE,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,+DAA4D;EAC5D,oBAAoB;EACpB,kBAAkB;AACpB;;;AAGA;EACE,iCAAiC;EACjC,oBAAoB;AACtB;;;AAKA;EACE,+BAA+B;AACjC;;AAEA;EACE,+BAA+B;AACjC;;;AAGA;EACE,+BAA+B;AACjC","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: #C6E6D3;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n\n@font-face {\n  font-family: 'Ade Display';\n  src: url('./fonts/ade-display/Ade-Display.otf') format('opentype');\n  font-weight: lighter;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Yahren';\n  src: url('./fonts/Yahren/Yahrenv2ei.ttf') format('truetype');\n  font-weight: lighter;\n  font-style: normal;\n}\n\n\nh1 {\n  font-family: 'Ade Display', serif;\n  font-weight: lighter;\n}\n\n@import '~typeface-mina';\n\n\np {\n  font-family: \"Mina\", sans-serif;\n}\n\na{\n  font-family: \"Mina\", sans-serif;\n}\n\n\nbutton {\n  font-family: \"Mina\", sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
