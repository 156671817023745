// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  transition: background-color 1.5s, color 0.5s;
  background-color: #C6E6D3;
  color: #000000;
}

.body-container.project-visible,
body.project-visible,
body.project-visible p,
body.project-visible button,
body.project-visible h1,
body.project-visible h2 {
  color: #ffffff !important;
  transition: background-color 1.5s, color 0.2s;
}

body.project-visible p,
body.project-visible button,
body.project-visible h2 {
  color: #ffffff;
}

.body-container.project-visible .m-scroll {
  border-color: white;
}

body.m-scroll__title h1 {
  margin: 0;
  font-size: 9px;
  color: white;
  transition: all 1s ease;
}

/* Animation de fond pour tous les éléments body-container */
.body-container {
  will-change: background-color; /* Optimisation pour l'animation */
}

.body-container.project-visible {
  background-color: #000000;
  color: #ffffff !important;
  transition: background-color 1.5s, color 0.2s; /* Durée de transition plus lente pour le fond sur Projects */
}

/* Animation pour le retour au fond de base */
.body-container:not(.project-visible) {
  transition: background-color 1.5s, color 0.2s;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Body/Body.css"],"names":[],"mappings":"AAAA;EACE,6CAA6C;EAC7C,yBAAyB;EACzB,cAAc;AAChB;;AAEA;;;;;;EAME,yBAAyB;EACzB,6CAA6C;AAC/C;;AAEA;;;EAGE,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,cAAc;EACd,YAAY;EACZ,uBAAuB;AACzB;;AAEA,4DAA4D;AAC5D;EACE,6BAA6B,EAAE,kCAAkC;AACnE;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,6CAA6C,EAAE,6DAA6D;AAC9G;;AAEA,6CAA6C;AAC7C;EACE,6CAA6C;AAC/C","sourcesContent":["body {\n  transition: background-color 1.5s, color 0.5s;\n  background-color: #C6E6D3;\n  color: #000000;\n}\n\n.body-container.project-visible,\nbody.project-visible,\nbody.project-visible p,\nbody.project-visible button,\nbody.project-visible h1,\nbody.project-visible h2 {\n  color: #ffffff !important;\n  transition: background-color 1.5s, color 0.2s;\n}\n\nbody.project-visible p,\nbody.project-visible button,\nbody.project-visible h2 {\n  color: #ffffff;\n}\n\n.body-container.project-visible .m-scroll {\n  border-color: white;\n}\n\nbody.m-scroll__title h1 {\n  margin: 0;\n  font-size: 9px;\n  color: white;\n  transition: all 1s ease;\n}\n\n/* Animation de fond pour tous les éléments body-container */\n.body-container {\n  will-change: background-color; /* Optimisation pour l'animation */\n}\n\n.body-container.project-visible {\n  background-color: #000000;\n  color: #ffffff !important;\n  transition: background-color 1.5s, color 0.2s; /* Durée de transition plus lente pour le fond sur Projects */\n}\n\n/* Animation pour le retour au fond de base */\n.body-container:not(.project-visible) {\n  transition: background-color 1.5s, color 0.2s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
