// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.m-scroll {
  display: flex;
  position: relative;
  width: 100%;
  height: 60px;
  margin: auto;
  border-top: 1px black solid;
  border-bottom: 1px black solid;
  overflow: hidden;
  z-index: 1;
  padding-top: 1rem;
}
.m-scroll__title {
  display: flex;
  position: absolute;
  top: 34px;
  left: 0;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  transform: scale(2);
  transition: all 10s ease;
}
.m-scroll__title > div {
  display: flex;
  animation: scrollText 25s infinite linear;
}
.m-scroll__title h1 {
  margin: 0;
  font-size: 9px;
  color: black;
  transition: all 10s ease;
}

@keyframes scrollText {
  from {
    transform: translateX(0%);
 }
  to {
    transform: translateX(-20%);
 }
}


@media only screen and (max-width: 767px) {
  .m-scroll {
    height: 20px;
    padding-top: 1rem;
  }
  .m-scroll__title {
    top: 15px;
  }
  .m-scroll__title h1 {
    font-size: 5px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/home/infiniteScolling.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,2BAA2B;EAC3B,8BAA8B;EAC9B,gBAAgB;EAChB,UAAU;EACV,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,mBAAmB;EACnB,2BAA2B;EAC3B,mBAAmB;EACnB,mBAAmB;EACnB,wBAAwB;AAC1B;AACA;EACE,aAAa;EACb,yCAAyC;AAC3C;AACA;EACE,SAAS;EACT,cAAc;EACd,YAAY;EACZ,wBAAwB;AAC1B;;AAEA;EACE;IACE,yBAAyB;CAC5B;EACC;IACE,2BAA2B;CAC9B;AACD;;;AAGA;EACE;IACE,YAAY;IACZ,iBAAiB;EACnB;EACA;IACE,SAAS;EACX;EACA;IACE,cAAc;EAChB;AACF","sourcesContent":[".m-scroll {\n  display: flex;\n  position: relative;\n  width: 100%;\n  height: 60px;\n  margin: auto;\n  border-top: 1px black solid;\n  border-bottom: 1px black solid;\n  overflow: hidden;\n  z-index: 1;\n  padding-top: 1rem;\n}\n.m-scroll__title {\n  display: flex;\n  position: absolute;\n  top: 34px;\n  left: 0;\n  align-items: center;\n  justify-content: flex-start;\n  white-space: nowrap;\n  transform: scale(2);\n  transition: all 10s ease;\n}\n.m-scroll__title > div {\n  display: flex;\n  animation: scrollText 25s infinite linear;\n}\n.m-scroll__title h1 {\n  margin: 0;\n  font-size: 9px;\n  color: black;\n  transition: all 10s ease;\n}\n\n@keyframes scrollText {\n  from {\n    transform: translateX(0%);\n }\n  to {\n    transform: translateX(-20%);\n }\n}\n\n\n@media only screen and (max-width: 767px) {\n  .m-scroll {\n    height: 20px;\n    padding-top: 1rem;\n  }\n  .m-scroll__title {\n    top: 15px;\n  }\n  .m-scroll__title h1 {\n    font-size: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
